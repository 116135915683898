import React, {useState} from 'react';
import { Route, Switch } from 'react-router-dom';

import SideNoticias from './noticias';
import SideRecientes from './recientes';
import SideMenu from './menu';
import SideProgramacion from './programacion';
import SideQuienesSomos from './quienes-somos';
import SideSuscripcion from './suscripcion';

import './styles.scss';

class Sidebar extends React.Component { 
    
    constructor(props){
        super(props)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location !== this.props.location){

            if(this.props.location !== '/' && this.props.location !== '/informativo') {
                this.props.magnifySide(false);
            }

            if(this.props.location === '/informativo') {
                this.props.magnifySide(true);
            }


            console.log("ACTUALIZO!", prevProps, this.props);
        }
    }

    render(){
        return (
            <section id='sidebar'>
                <Switch>
                    <Route exact path='/'><SideNoticias magnifySide = {this.props.magnifySide} isMagnified={this.props.isMagnified} location={this.props.location} /></Route>
                    <Route exact path='/informativo'><SideNoticias magnifySide = {this.props.magnifySide} isMagnified={this.props.isMagnified} location={this.props.location} /></Route>
                    <Route exact path='/menu'><SideMenu /></Route>
                    <Route exact path='/programacion'><SideProgramacion /></Route>
                    <Route exact path='/quienes-somos'><SideQuienesSomos /></Route>
                    <Route exact path='/suscripcion'><SideSuscripcion /></Route>
                    <Route exact path='/formulario-de-suscripcion'><SideSuscripcion /></Route>
                    <Route path='/:slug1/:slug2?/:slug3?' render={ function(props) { return ( <SideRecientes /> ) } } />
                </Switch>
            </section>
        )
    }
};

export default Sidebar;