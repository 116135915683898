import React from 'react';
import WpList from 'wp/list';
import Filtro from './filtro';
import './styles.scss';
import moment from 'moment';


class MainNoticias extends React.Component {

    constructor(props) {
        super(props);

        var fechaFiltro = moment().format('YYYY-MM-DD[T23:59:00]');
        if(typeof(props.filter) != 'undefined' && props.filter != '') {
            fechaFiltro = props.filter
        }

        this.state = {
            fechaFiltro: fechaFiltro
        }
        this.setQuery = this.setQuery.bind(this);
    }
    
    setQuery(filtro) {
        //console.log('Se ejecuta filtro: ', filtro);
        if(filtro != null) {
            this.setState({
                fechaFiltro: moment(filtro).format('YYYY-MM-DD[T23:59:00]')
            })
        }
    }

    render() {
        const queries = [
            '_embed',
            'orderby=date',
            'order=desc',
            'before='+ this.state.fechaFiltro
        ]

        var clsLoc = 'home';
        if(typeof(this.props.location) !== 'undefined' && this.props.location === '/informativo' ) {
            clsLoc = 'info';
        }

        var button = '';
        if(typeof(this.props.button != 'undefined')) {
            button = this.props.button;
        }

        //console.log("PREBUTTON: ",button);

        var filter = '';
        if(typeof(this.props.filter != 'undefined')) {
            filter = this.props.filter;
        }

        return (
            <section id='main-noticias' className={clsLoc}>
                <Filtro fixed={this.props.fixed} fecha={this.state.fechaFiltro} setQuery={this.setQuery} static={false} button={button} />
                <WpList template={4} queries={queries} debug={false} videoAsFeaturedImage={true} showFeaturedAudio={true} infiniteScroll={true} />
            </section>
        )
    }
};

export default MainNoticias;