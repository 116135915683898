import React from 'react';
import Video from './video';
import Publis from './publis';
import './styles.scss';

function SideNoticias(props) {
    return (
        <section id='side-noticias'>
            <Video isMagnified={props.isMagnified} magnifySide={props.magnifySide}/>
            <Publis />
        </section>
    )
}

export default SideNoticias;