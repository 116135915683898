import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from "react-router-dom";

import MainNoticias from './noticias';
import MainMenu from './menu';
import MainProgramacion from './programacion';
import MainQuienesSomos from './quienes-somos';
import MainSuscripcion from './suscripcion';
import WpSiteContent from 'wp/site/content';
import Filtro from './noticias/filtro';
import './styles.scss';

function Main(props) {


  
    return (
        <section id='main'>
          <Switch>
            <Route exact path='/' fixed={props.fixed} render={function(props){

              var preSelectedButton = '';
              if(typeof(props.location.button) != 'undefined'){
                preSelectedButton = props.location.button;
              }

              var preFilter = '';
              if(typeof(props.location.filter) != 'undefined'){
                preFilter = props.location.filter;
              }

              //console.log("PROPS",props);

              return(<MainNoticias location='/' button={preSelectedButton} filter={preFilter}/>)

            }} />
            <Route exact path='/informativo'><MainNoticias fixed={props.fixed} location='/informativo' /></Route>
            <Route exact path='/menu'><MainMenu /></Route>
            <Route exact path='/programacion'><MainProgramacion /></Route>
            <Route exact path='/quienes-somos'><MainQuienesSomos /></Route>
            <Route exact path='/suscripcion'><MainSuscripcion /></Route>
            <Route path='/:slug1/:slug2?/:slug3?' fixed={props.fixed} render={ function(props) { return ( 
              <div  className="noticias-container">
                { props.location.pathname !== '/formulario-de-suscripcion' &&
                  <Filtro setQuery={function(){}} static={true} />
                }
                <WpSiteContent {...props} postTemplate={3} archiveTemplate={4} /> 
              </div>
              ) } } />
          </Switch>
        </section>
    )
};

export default Main;