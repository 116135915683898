import React from 'react';
import WpSidebar from 'wp/sidebar';
import './styles.scss';
import { withRouter } from "react-router-dom";

class Video extends React.Component {

    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if(this.props.location.pathname != '/informativo') {
            this.props.magnifySide(false);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname){
            if(this.props.location.pathname !== '/informativo') {
                this.props.magnifySide(false);
            }
        }
    }


    handleClick(magnify) {
        if(!magnify){
            this.props.magnifySide(false)
        } else {
            this.props.magnifySide(true)
            this.props.history.push('/informativo');
        }
    }

    render() {
        return (
            <section id='video'>
                { !this.props.isMagnified ?
                    <div className='video-card' onClick={() => this.handleClick(true)}>
                        <WpSidebar pos='informativo-sidebar'/>
                    </div>
                : 
                <div>
                    <span className="close-video" onClick={() => this.handleClick(false)}>X</span>
                    <div className='youtube'>
                        <WpSidebar pos='video-informativo-sidebar'/>
                    </div>
                </div>
                }
            </section>
        )
    };
}
export default withRouter(Video);