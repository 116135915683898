import React from 'react';
import WpMenu from 'wp/menu';
import './styles.scss';


function SideMenu() {
    return (
        <section id='side-menu'>
            <WpMenu location='accesos-menu-sidebar-location' />
        </section>
    );
}

export default SideMenu